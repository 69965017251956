import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'app/_services/global.service';

@Component({
  selector: 'app-phenos',
  templateUrl: './phenos.component.html',
  styleUrls: ['./phenos.component.scss']
})
export class PhenosComponent implements OnInit {

  constructor(
    public http: HttpClient,
    public global: GlobalService
  ) { }
  phenos = {};
  ngOnInit(): void {
    for (let i = 0; i < 10; i++) {
      this.http.get(`https://2025.galati.sk/api-25/pheno/?pheno=${i}&lang=${this.global.lang}`).toPromise().then((res) => {
        this.phenos[`${i}`] = res;
        console.log(this.phenos)
      })

    }

  }

}
