<div style="position:relative;">
  <h2 mat-dialog-title class="week-title"><span>{{data.vineyard.Alias}} <span class="chybajuce-tyzdne" *ngIf="(vineServ.weekOfYear -  weekOfSeason +1)>0">({{"Chýbajúce týždne" | translate}}: {{vineServ.weekOfYear -  weekOfSeason +1 }})</span></span>
  </h2>

  <mat-dialog-content>
    <div *ngIf="!galatiInputGet" style="padding:100px 0;">
      <app-loading></app-loading>
    </div>
    <div class="dialog-content" *ngIf="galatiInputGet">
    <div class="content" id="chooseContent">
      <div class="row" *ngIf="galatiInputGet && galatiInputGet.severityOptions.length>0">
        <h2>{{"Stav napadnutia viniča chorobami" | translate}}</h2>
        <div class="col col-md-4 col-sm-12" style="padding-left: 0;">
          <h3>{{"infect.m" | translate}}
          </h3>
          <mat-form-field>
            <mat-select [(ngModel)]="galatiPost.m.severity" placeholder="{{ 'Disease evaluation' | translate }}">
              <mat-option class="severity-option" *ngFor="let severity of galatiInputGet.severityOptions" value="{{severity.severity}}">
                <img class="severity-img" src="https://2025.galati.sk{{severity.leaf}}" *ngIf="severity.leaf">
                <img class="severity-img" src="https://2025.galati.sk{{severity.grape}}" *ngIf="severity.grape">
                {{severity.severity}} %
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col col-md-4 col-sm-12" style="padding-left: 0;">
          <h3>
            {{"infect.p" | translate}}
          </h3>
          <mat-form-field>
            <mat-select [(value)]="galatiPost.p.severity" placeholder="{{ 'Disease evaluation' | translate }}">
              <mat-option class="severity-option" *ngFor="let severity of galatiInputGet.severityOptions" value="{{severity.severity}}">
                <img class="severity-img" src="https://2025.galati.sk{{severity.leaf}}" *ngIf="severity.leaf">
                <img class="severity-img" src="https://2025.galati.sk{{severity.grape}}" *ngIf="severity.grape">
                {{severity.severity}} %
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col col-md-4 col-sm-12" style="padding-left: 0;">
          <h3>
            {{"infect.b" | translate}}
          </h3>
          <mat-form-field>
            <mat-select [(value)]="galatiPost.b.severity" placeholder="{{ 'Disease evaluation' | translate }}">
              <mat-option class="severity-option" *ngFor="let severity of galatiInputGet.severityOptions" value="{{severity.severity}}">
                <img class="severity-img" src="https://2025.galati.sk{{severity.leaf}}" *ngIf="severity.leaf">
                <img class="severity-img" src="https://2025.galati.sk{{severity.grape}}" *ngIf="severity.grape">
                {{severity.severity}} %
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row"></div>
      <div class="row" style="margin:0 0 25px; padding:0; padding-bottom: 20px; background-color: #f7f7f7;border-bottom: 1px solid #ddd;border-top: 1px solid #ddd;" *ngIf="data.vineyard && data.vineyard.usage !== 'shared'">
        <mat-divider>
        </mat-divider>
        <h2 style="margin-top: 20px;margin-bottom: 20px;">{{"Ošetrenie vinohradu" | translate}}: <span class="redWeekNum">{{datumOtazkyOsetrovania}}</span></h2>
        <h3 style="font-size: 19px; text-align: center; display: inline-block; margin:0 auto;font-weight: 500;">{{"Postrekoval sa vinohrad v daný týždeň?"| translate}}
          <span class="postrek-btns">
            <button mat-button [ngClass]="{' btn btn-primary': striekaloSa}" (click)="striekaloSa = true">{{"yes" | translate}}</button>
            <button mat-button [ngClass]="{'btn btn-primary': !striekaloSa}" (click)="striekaloSa = false">{{"no" | translate}}</button>
          </span>

        </h3>
        <mat-divider>
        </mat-divider>
        <div class="row" style="margin-top: 35px;  width: 100%;" [ngClass]="{'dn': !striekaloSa}" *ngIf="data.vineyard && data.vineyard.usage !== 'shared'">
          <!-- <mat-expansion-panel [expanded]="" style="  width: 100%;">
            <mat-expansion-panel-header>
              <mat-panel-title> Vybrať prípravky </mat-panel-title>
            </mat-expansion-panel-header> -->
          <h2 style="margin-top: 0;"> {{"Postreky za predošlý týždeň" | translate}}</h2>
          <p style="  text-align: center;width: 100%;margin-top: -10px;">{{"Označte všetky postreky, ktorými ste predošlý týždeň ošetrovali vinič"|translate}}</p>
          <div class="col col-xl-6" style="padding-left: 0; padding-bottom: 15px;padding-right: 0;margin:0 auto;">
            <mat-form-field>
              <input matInput placeholder="{{'Vyhľadajte produkt alebo výrobcu' | translate}}" [(ngModel)]="searchTerm" (input)="searchPests()">
            </mat-form-field>
          </div>
          <div class="col col-xl-9" style="padding-left: 0; padding-bottom: 15px;padding-right: 0;margin:0 auto;text-align: center;" *ngIf="data.vineyard.galatiSeason.vineyard.diseaseManagementScheme !== 'expert'">
            <mat-checkbox [(ngModel)]="ignoreScheme" (ngModelChange)="pestSchemeOnOff($event)">{{"Zobraziť postreky zo všetkých schém ochrany viniča?" | translate}}</mat-checkbox>
          </div>
          <div class="col col-xl-9" style="padding-left: 0; padding-bottom: 15px;padding-right: 0;margin:0 auto;" *ngIf="chosenPests">
          
            <mat-chip-list #chipList style="margin-left: -4px !important; margin-bottom: 25px;">
              <span style="font-weight: bold;" *ngIf="chosenPests.length>=1">{{"Zvolené prípravky" | translate}}:</span> <mat-chip *ngFor="let chosenPest of chosenPests">
                {{getPestName(chosenPest) | slice:0:20}} <span *ngIf="getPestName(chosenPest).length>25">...</span>
                <span matChipRemove (click)="removePest(chosenPest)" style="  margin-top: -6px; margin-right: 3px;">
                  <mat-icon>cancel</mat-icon>
                </span>
              </mat-chip>
            </mat-chip-list>
          </div>
          <div class="col col-xl-12" style="padding-left: 0; padding-bottom: 35px;">
            <mat-accordion>
              <mat-expansion-panel *ngFor="let pest of pestResult" [ngStyle]="{'display': !pest.product? 'none':'block'} " style="line-height: 1.618em;">
                <mat-expansion-panel-header>
                  <mat-panel-title style="">
                    <mat-checkbox color="accent" [checked]="toggleCheckBox(pest.id)" style="margin-right: 10px;" (click)="$event.stopPropagation()" (change)="setPest(pest.id)"></mat-checkbox> <b>{{pest.product | slice:0:135}}
                      <span *ngIf="pest.product.length > 20">...</span></b> <small style="margin-left:15px;" *ngIf="pest.vendor">({{pest.vendor}})</small>
                  </mat-panel-title>
                  <mat-panel-description style="justify-content: end;">
                    <span style="color:black;margin-left:10px;margin-right: 15px; padding-right:15px;">
                      <span class="pest-infect" *ngIf="pest.intensity.m>0">{{"infect.m" | translate | slice:0:4}}. </span>
                      <span class="pest-infect" *ngIf="pest.intensity.p>0">{{"infect.p" | translate | slice:0:4}}. </span>
                      <span class="pest-infect" *ngIf="pest.intensity.b>0">{{"infect.b" | translate | slice:0:4}}. </span>
                    </span>
                  </mat-panel-description>
                </mat-expansion-panel-header>
                <div *ngIf="data.vineyard.galatiSeason.vineyard.diseaseManagementScheme !== 'expert'">
                <div>
                  <span *ngIf="pest.notes"><b>{{"Poznámky"|translate}}: </b> {{pest.notes[0]}}</span>
                </div>
                <div>
                  <span><b>{{"Bližšie info" | translate}}: </b>
                    <a href="{{url}}" target="_blank" *ngFor="let url of pest.addresses">{{"Odkaz" | translate}}</a>
                  </span>
                </div>
                <div>
                  <span style="display:flex;align-items:center;"><b>{{"Odporúčané" | translate}}?: </b>
                    <mat-icon *ngIf="pest.recommended" style="font-size: 17px;width: 17px;height: 17px;margin-left: 7px;">check</mat-icon>
                    <mat-icon *ngIf="!pest.recommended" style="font-size: 17px;width: 17px;height: 17px;margin-left: 7px;">close</mat-icon>
                  </span>
                </div>
                <div>
                  <span><b>{{"Účinné látky"|translate}}: </b> <span *ngFor="let res of pest.antiResistence;let j=index;">{{res}}<span *ngIf="j < pest.antiResistence.length-1">, </span></span></span>
                </div>
                <div>
                  <span><b>{{"V akej zlúčenine sa nachádza účinná látka" | translate}}: </b> <span *ngFor="let substance of pest.substances;let k = index;">{{substance}}<span *ngIf="k < pest.substances.length-1">, </span></span></span>
                </div>
                <div>
                  <span style="display:flex;align-items:center;"><b>{{"Miešateľnosť"|translate}}: </b>
                    <mat-icon *ngIf="pest.mixable" style="font-size: 17px;width: 17px;height: 17px;margin-left: 7px;">check</mat-icon>
                    <mat-icon *ngIf="!pest.mixable" style="font-size: 17px;width: 17px;height: 17px;margin-left: 7px;">close</mat-icon>
                  </span>
                </div>
              </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <!-- </mat-expansion-panel> -->
        </div>
      </div>
      <!-- 
       -->
      <!-- 

        -->
      <!-- 

       -->
      <div class="row" style="margin-bottom: 20px;align-items: flex-start;justify-content: space-around;">
        <h2 style="margin-top: 0;">{{"Expected phenophase"| translate}}: <span class="redWeekNum">{{ weekOfSeasonDates }}</span> </h2>
        <!-- <div *ngIf="galatiInputGet">{{ "Data input for week" | translate }} <span class="redWeekNum" [ngClass]="{'redWeekNumTransition': redWeekNumTransition}">{{ weekOfSeasonDates }} </span></div> -->
        <mat-spinner class="m0a" [diameter]="50" *ngIf="isLoading"></mat-spinner>
        <div class="phenocards row" *ngIf="!isLoading" style="margin-bottom: 20px;align-items: flex-start;justify-content: space-around;">
          <mat-card class="pheno-card" *ngFor="let pheno of phenoOptions" [ngClass]="{'chosen': chosenPhenophase===pheno.key}">
            <div class="chosen-overlay" *ngIf="chosenPhenophase===pheno.key">
              <div class="mat-icon-wrapper">
                <mat-icon class="check">check</mat-icon>
                <mat-icon class="cancel" (click)="chosenPhenophase = null">clear</mat-icon>
              </div>
            </div>
            <carousel class="carousel" mat-card-image cellWidth="100%" counter="true" style="margin:0px auto 15px auto;max-width: 100%; border-radius: 10px;">
              <div class="carousel-cell" *ngFor="let photo of pheno.phenoPhotos">
                <img src="https://2025.galati.sk{{photo}}" style="object-fit: cover;border-radius:10px;">
              </div>
              <div class="carousel-cell">
                <img src="https://2025.galati.sk{{pheno.phenoIcon}}" style="object-fit: cover;border-radius:10px;">
              </div>
              <div class="carousel-cell">
                <img src="https://2025.galati.sk{{pheno.phenoIconBW}}" style="object-fit: cover;border-radius:10px;">
              </div>
            </carousel>
            <mat-card-header class="pheno-card-header">
              <img mat-card-avatar class="example-header-image pheno-header-image" src="https://2025.galati.sk{{pheno.phenoIconBW}}">
              <mat-card-title style="margin-bottom:0;">{{pheno.value}}</mat-card-title>
          
            </mat-card-header>
            <mat-card-content>
              <p class="phenoDescription" class="phenoDescription" *ngIf="pheno.phenoDescription">
                <span *ngIf="pheno.phenoDescription.length > 60 && pheno.expanded">
                  {{pheno.phenoDescription }}
                </span>
                <span *ngIf="pheno.phenoDescription.length <= 60 || !pheno.expanded">
                  {{pheno.phenoDescription | slice:0:60}}<span *ngIf="pheno.phenoDescription.length > 60">...</span> </span>
                <strong *ngIf="pheno.phenoDescription.length > 60 && !pheno.expanded" (click)="pheno.expanded = true">
                  {{ "Read more" | translate }}</strong>
                <strong *ngIf="pheno.phenoDescription.length > 60 && pheno.expanded" (click)="pheno.expanded = false">{{ "Hide" | translate }}</strong>
              </p>
            </mat-card-content>
            <mat-card-actions>
              <button class="btn btn-primary" type="button" (click)="chosenPhenophase=pheno.key;" *ngIf="chosenPhenophase!==pheno.key;" style="width: 100%;">{{"Choose" | translate}}</button>
              <button class="btn btn-outline" type="button" *ngIf="chosenPhenophase===pheno.key;" style="width: 100%;">{{"Chosen" | translate}}</button>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
    </div>
    <div class="btn-wrapper">
    <button mat-button class="btn-pheno-close" (click)="closePrompt = true">{{"Close"|translate}}</button>
    <button mat-button class="btn btn-primary btn-submit-pheno" [disabled]="!chosenPhenophase" *ngIf="!isLoading" (click)="submitPhenosToGalati(chosenPhenophase,data.vineyard,weekOfSeason);">{{"Submit" | translate}}</button>
  </div>
</div>
  </mat-dialog-content>
  <!-- <mat-dialog-actions>
          </mat-dialog-actions> -->
  <div *ngIf="closePrompt" class="closePrompt">
    <h2>{{"phenoClosePromptTitle"|translate}}</h2>
    <p>
      {{"phenoClosePromptDesc" | translate}} <br>
    </p>
    <p class="well">{{"phenoClosePromptTitle"|translate}}</p>
    <div>
        <button mat-button class="btn-pheno-close" [mat-dialog-close]="data.vineyard">{{"yes"|translate}}</button>
        <button mat-button class="btn btn-primary " (click)="closePrompt = false">{{"no"|translate}}</button>
    </div>
  </div>
</div>