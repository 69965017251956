
<div *ngIf="vineyard && vineyard.galatiSeason && vineyard.galatiSeason.season">
  <div class="well" style="color: #686868;" *ngIf="vineyard.galatiSeason.season[vineyard.galatiSeason.season.length-1].info">{{vineyard.galatiSeason.season[vineyard.galatiSeason.season.length -1].info[0]}}</div>
</div>
<div>
  <div class="galati-wrapper" *ngIf="!vineyard.galatiSeason">
    {{"Here you'll find the data about infection pressure on your vineyard. But first we need to gather the first week's weather data." | translate}}
  </div>
  <div class="galati-wrapper" *ngIf="season">
    <mat-menu #appMenu="matMenu">
      <button mat-menu-item *ngIf="infection !== 'p'" (click)="changeInfectionClick('p')" [routerLink]="['/vineyards', vineyard.ActivationKey]" [queryParams]="{tab:'infection',infection:'p'}">{{"infect.p" | translate}}</button>
      <button mat-menu-item *ngIf="infection !== 'm'" (click)="changeInfectionClick('m')" [routerLink]="['/vineyards', vineyard.ActivationKey]" [queryParams]="{tab:'infection',infection:'m'}">{{"infect.m" | translate}}</button>
      <button mat-menu-item *ngIf="infection !== 'b'" (click)="changeInfectionClick('b')" [routerLink]="['/vineyards', vineyard.ActivationKey]" [queryParams]="{tab:'infection',infection:'b'}">{{"infect.b" | translate}}</button>
    </mat-menu>
    <div *ngIf="season">
      <div class="galati-heading">
        <h2>
          <span class="infectName" [matMenuTriggerFor]="appMenu">
            {{infectionTitle}}
            <mat-icon> keyboard_arrow_down </mat-icon>
          </span>
        </h2>
      </div>
      <h3 *ngIf="season" style="font-weight: 500;display: flex;text-align: center;justify-content: center;"> {{ season[season.length - 1][infection].msg}} {{ season[season.length - 1][infection].signalMsg }}
      </h3>
      <div class="grid-container" *ngIf="season">
        <div class="highlight" [ngStyle]="{ 'left': highlightLeft + 'px' }" #highlight></div>
        <div class="grid-head">
          <div class="grid-head--empty"> <span class="desktop-only galati-chart-help" (click)="openGalatiChartDocDialog()" matTooltip="{{'Open the infection chart explanation' | translate}}"><i class="material-icons material-icons--outlined " style="margin-right: 5px;font-size: 20px;margin-top: 3px;">info</i>{{"Help" | translate}}</span>
            <div class="month mobile-only"></div>
          </div>
          <div class="grid-head--numbers" matTooltip="{{'Vineyard\'s sensitivity for:' | translate}} {{infectionTitle}} = {{vineyard.galatiSeason.vineyard.sensitivity[infection]}}">
            <div #headNums *ngFor="let number of [0,1,2,3,4,5,6,7,8,9]" class="head-num head-num--{{number}}" [ngClass]="{'active': vineyard.galatiSeason.vineyard.sensitivity[infection] === number}">{{number}}</div>
          </div>
          <div class="grid-head--icon">
            <i class="fi flaticon-rain-1 rain-icon"></i>
          </div>
        </div>
        <div class="month-repeat" *ngFor="let month of months | reverse; let j=index ">
          <div class="mesiac mobile-only">{{monthsInSeason[monthsInSeason.length - 1 - j] | translate}}</div>
          <div class="grid-body">
            <div class="grid-month">
              <div class="month">{{monthsInSeason[monthsInSeason.length - 1 - j] | translate}}</div>
            </div>
            <div class="grid-weeks">
              <div class="grid-week grid-week--{{i+1}}" [ngClass]="(week.weekNum === currentWeekNum) ? 'currentWeekDate' : (week.weekNum < currentWeekNum) ? 'historyWeekDate' : (week.weekNum > currentWeekNum) ? 'futureWeekDate' : -1" *ngFor="let week of month | reverse; let i=index">{{week.monday | date:'d.'}} - {{week.weekEnd | date:'d.'}}</div>
            </div>
            <div class="grid-bars">
              <div class="grid-bars-row" [ngClass]="{'future-bar': week.weekNum > currentWeekNum}" *ngFor="let week of month | reverse; let i=index">
                <mat-icon class="posa" [ngStyle]="vineyard.galatiSeason.vineyard.sensitivity[infection] === 9 && {'left':'5px'}" style="right: 5px;" matTooltip="{{week[infection].msg}} {{week[infection].signalMsg}}" [ngClass]="{'icon-white': (vineyard.galatiSeason.vineyard.sensitivity[infection] !== 9  && ((((week[infection].inf[0] + 1) * 10)===100)) || (((week[infection].inf[1] + 1) * 10) === 100)) || ((vineyard.galatiSeason.vineyard.sensitivity[infection] === 9) && ((week[infection].inf[0] + 1 !== 0) || (week[infection].inf[1] + 1 !== 0))) }">info_outlined</mat-icon>
                <div class="infection yellow" [ngStyle]="{'width': ((week[infection].inf[0] + 1) * 10) + '%'}"></div>
                <div class="infection red" [ngStyle]="{'width': ((week[infection].inf[1] + 1) * 10) + '%'}"></div>
                <div class="bar-num" *ngFor="let number of [0,1,2,3,4,5,6,7,8,9]" [ngClass]="{'active': vineyard.galatiSeason.vineyard.sensitivity[infection] === number}" matTooltip="{{'Phenophase'|translate}}: {{week.phenoMsg}}"> <img style="width:27px;height:27px;border-radius:60px;" src="https://2025.galati.sk{{week.phenoIconBW}}" alt="" *ngIf="vineyard.galatiSeason.vineyard.sensitivity[infection] === number && week.phenoIconBW"> </div>
              </div>
            </div>
            <div class="grid-rains">
              <div class="grid-rain grid-rain--{{i+1}}" *ngFor="let week of month | reverse; let i=index">
                <ng-container [ngSwitch]="(week.weekNum === currentWeekNum) ? 1 : (week.weekNum < currentWeekNum) ? 2 : (week.weekNum > currentWeekNum) ? 3 : -1">
                  <ng-container *ngSwitchCase="1">
                    {{"measuring"| translate}}
                  </ng-container>
                  <ng-container *ngSwitchCase="2">
                    {{week.temp | number:"1.0-1"}} °C,
                    {{week.rainfall | number:"1.0-1"}} mm
                  </ng-container>
                  <ng-container *ngSwitchCase="3"> <span style="color:black;font-weight: 600 !important;">{{"next week" | translate | slice:0:10}}. </span></ng-container>
                </ng-container>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>