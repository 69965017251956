import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
} from "@angular/core";
import { format, addDays, startOfMonth, parseISO, getMonth } from "date-fns";
import { Season } from "../_classes/season";
import { TranslateService } from "@ngx-translate/core";
import { Vineyard } from "app/_classes/vineyard";
import { VineyardService } from "app/_services/vineyard.service";
import { GalatiService } from "app/_services/galati.service";
import { untilDestroyed } from "ngx-take-until-destroy";
import { MatDialogRef, MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { GlobalService } from "app/_services/global.service";
import { data } from 'jquery';

// declare var $: any;
@Component({
  selector: "app-galati",
  templateUrl: "./galati.component.html",
  styleUrls: ["./galati.component.scss"],
})
export class GalatiComponent implements OnInit {
  @Input() public vineyard: Vineyard;
  @Input() ActivationKey: string;
  // @ViewChildren("highlightContainer") highlightContainer: QueryList<ElementRef>;
  @ViewChild("highlight", { static: true }) highlight: ElementRef;
  @ViewChildren("headNums") headNums: QueryList<ElementRef>;
  activehHeadNum;
  sezonaTemp;
  sezonaSorted;
  seasonMonths: Season;
  trend;
  galatiForecast;
  monthsInSeason;
  months = [];
  currentWeekNum;
  highlightLeft = 0;
  infection = "m"; // p, m, b
  infectionTitle = "múčnatka";
  fenofazy = [
    "zimný odpočinok, Zimné púčiky sú uzavreté do šupín",
    "slzenie",
    "pučanie",
    "vývin listov",
    "rozkvitanie (10% čiapočiek opadlo)",
    "kvitnutie (50% čiapočiek opadlo)",
    "koniec kvitnutia (80% čiapočiek opadlo)",
    "vývoj bobúľ - veľkosť hrášku",
    "Začiatok uzatvárania strapcov - bobule sa začínajú navzájom dotýkať",
    "Zamäkanie (mäknutie) bobúľ",
  ];
  season: any;
  galatiVineyard: any;
  worksExpanded = false;

  changeInfection(infectCode) {
    this.infection = infectCode;
    switch (infectCode) {
      case "b":
        this.infection = "b";
        this.infectionTitle = this.translate.instant("infect.b");
        break;
      case "p":
        this.infection = "p";
        this.infectionTitle = this.translate.instant("infect.p");
        break;
      case "m":
        this.infection = "m";
        this.infectionTitle = this.translate.instant("infect.m");
        break;
      default:
        this.infection = "m";
        this.infectionTitle = this.translate.instant("infect.m");
        break;
    }
  }
  changeInfectionClick(infectCode) {
    this.changeInfection(infectCode);
    setTimeout(() => {
      this.moveHighlighter();
    }, 1);
  }
  constructor(
    public translate: TranslateService,
    public galati: GalatiService,
    public vineyardService: VineyardService,
    public global: GlobalService,
    public dialog: MatDialog,
    public galatiService: GalatiService,
    public http: HttpClient,
    public _Activatedroute: ActivatedRoute
  ) {
    window.onresize = () => {
      this.moveHighlighter();
    };



  }
  moveHighlighter() {
    this.activehHeadNum = this.headNums.filter((element, index) =>
      element.nativeElement.classList.contains("active")
    );
    this.activehHeadNum = this.activehHeadNum[0];
    const highlightWidth = this.activehHeadNum.nativeElement.offsetWidth;
    this.highlightLeft =
      this.activehHeadNum.nativeElement.offsetLeft + highlightWidth / 2;
  }
  openGalatiChartDocDialog() {
    const dialogRef = this.dialog.open(DialogDocGalatiChart);
    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
    });
  }
  ngOnInit() {
    this._Activatedroute.queryParams.subscribe((queryParam) => {
      console.log("url query param", queryParam);
      if (
        queryParam["infection"] === "m" ||
        queryParam["infection"] === "b" ||
        queryParam["infection"] === "p"
      ) {
        this.changeInfection(queryParam["infection"]);
      } else {
        this.changeInfection("m");
      }
    });
    this.galatiService.galatiCalendar(new Date()).then((res) => {
      this.currentWeekNum = res["weekNum"];
    });

    this.seasonMonths = new Season();


  }
  ngAfterViewInit(): void {



    this.http
      .get(
        `https://api.openweathermap.org/data/2.5/onecall?lat=${this.vineyard.lat}&lon=${this.vineyard.lng}&lang=${this.global.lang}&units=metric&exclude=current,minutely,hourly&appid=${this.global.owmApiKey}`
      ).toPromise().then((result) => {
        console.warn(result);
        let rainSUM = 0;
        let tempSum = 0;
        let avgTemp;
        let days = 0;
        result["daily"].forEach((day) => {
          days++;
          tempSum += (day.temp.min + day.temp.max) / 2;
          if (day.rain) {
            rainSUM += day.rain;
          }
        });
        rainSUM = +(rainSUM.toFixed(1));
        avgTemp = +((tempSum / days).toFixed(1));
        this.http
          .post(
            `https://2025.galati.sk/api-25/input/forecast/?key=${this.vineyard.galatiKey}&lang=${this.global.lang}`,
            {
              temp: avgTemp,
              rainfall: rainSUM,
            }
          ).toPromise().then(data => {


    // this.galati.getSeason(this.vineyard).then(
    //   (data) => {
        this.vineyard.galatiSeason = data;
        console.log("galatiSeason", data);
        const seasonYear = data["year"];
        this.trend = data["trend"];
        this.season = data["season"];
        const firstDayOfYear = startOfMonth(new Date(seasonYear, 1, 1));
        let monthsInSeason = [];
        let currentMonth = [];
        let months = [];

        let lastMonth = "";
        let thisMonth = "";

        this.vineyardService.vineyardUpdateData(this.vineyard);

        if (this.season) {
          this.season.forEach((e, i, array) => {
            e.monday = new Date(parseISO(e.monday));
            e.weekEnd = addDays(e.monday, 6);
            e.month = getMonth(addDays(e.monday, 2)) + 1;
            e.monthReadable = format(addDays(e.monday, 2), "MMMM");

            if (lastMonth === "" && thisMonth === "") {
              lastMonth = e.monthReadable;
              monthsInSeason.push(e.monthReadable);
            }
            thisMonth = e.monthReadable;
            if (thisMonth !== lastMonth) {
              monthsInSeason.push(e.monthReadable);
              months.push(currentMonth);
              currentMonth = [];
              currentMonth.push(e);
              lastMonth = thisMonth;
            } else {
              currentMonth.push(e);
            }

            if (i === array.length - 1) {
              months.push(currentMonth);
              // console.log(i, e, "nastalo");
              setTimeout(() => {
                this.moveHighlighter();
              }, 0);
            }
          });
          this.monthsInSeason = monthsInSeason;
          this.months = months;
          console.log(this.monthsInSeason, this.months)
        }
          }).catch(error => console.log(error)
    );
      })
  }

  ngOnDestroy() {
    // To protect you, we'll throw an error if it doesn't exist.
  }
}
@Component({
  selector: "dialog-doc-galatiChart",
  template: `
    <h3 mat-dialog-title>{{ "Infection Chart Hint" | translate }}</h3>
    <mat-dialog-content class="mat-typography">
      <img
        src="/assets/img/docs/popis-galati-grafu_{{ global.lang }}.png"
        style="max-width:800px;width: 100%;"
        alt=""
      />
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close style="margin-top:15px;">
        {{ "Close" | translate }}
      </button>
    </mat-dialog-actions>
  `,
})
export class DialogDocGalatiChart {
  constructor(
    public dialogRef: MatDialogRef<DialogDocGalatiChart>,
    public global: GlobalService
  ) { }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
