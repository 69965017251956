<div class="main-content">
  <div class="page-content">
    <div class="container-fluid">
      <h2 class="page-heading" style="margin-bottom: 40px;
      ">{{'Phenophases' | translate}}</h2>
      <div class="row" *ngIf="phenos">
        <mat-card class="vineyard-card" *ngFor="let i of [0,1,2,3,4,5,6,7,8,9]">
          <mat-card-header>
            <div class="mat-card-title">{{phenos[i].phenophase}}. {{phenos[i].phenoMsg}}</div>
            <div class="mat-card-subtitle">{{phenos[i].phenoDescription}}</div>
          </mat-card-header>
          <mat-card-content>
            <carousel class="carousel" cellWidth="100%" mat-card-image counter="true" style="  height: 270px;margin:0px auto 15px auto; border-radius: 10px;">
              <div class="carousel-cell">
                <img src="https://2025.galati.sk{{phenos[i].phenoIcon}}" style="border-radius:10px;">
              </div>
              <div class="carousel-cell">
                <img src="https://2025.galati.sk{{phenos[i].phenoIconBW}}" style="border-radius:10px;">
              </div>
              <div class="carousel-cell" *ngFor="let photo of phenos[i].phenoPhotos">
                <img src="https://2025.galati.sk{{photo}}" style="border-radius:10px;">
              </div>
            </carousel>
            <h4 class="pheno-head">{{"Phenophase Tips" |translate}}</h4>
            <ul>
              <li *ngFor="let work of phenos[i].works">
                {{work}}
              </li>
            </ul>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>