import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'app/_services/global.service';
import { VineyardService } from 'app/_services/vineyard.service';
import { endOfWeek, format, getYear, startOfWeek } from 'date-fns';

@Component({
  selector: 'app-adminbar',
  templateUrl: './adminbar.component.html',
  styleUrls: ['./adminbar.component.scss']
})
export class AdminbarComponent implements OnInit {
  startDate: any;
  endDate: any;

  constructor(public vineyardService: VineyardService,
    public http: HttpClient,
    public global: GlobalService) { }

  async ngOnInit(): Promise<void> {
    this.onChange();
    let datesTest = await this.getDatesFromWeekNumV2(this.vineyardService.weekOfYear);
    console.log(new Date(datesTest["monday"]))
    console.log(startOfWeek(new Date(datesTest["monday"])))
    console.log(endOfWeek(new Date(datesTest["monday"])))
    console.log(format(new Date(datesTest["monday"]), "dd.MM.yyyy"))
    console.log(format(new Date(endOfWeek(new Date(datesTest["monday"]))), "dd.MM.yyyy"))

  }
  getDatesFromWeekNumV2(weekNum) {
    const url =
      `https://2025.galati.sk/api-25/calendar/week/?year=${getYear(new Date())}&weekNum=${weekNum}&lang=${this.global.lang}`;
    return this.http.get(url).toPromise();


  }
  async onChange() {
    let datesTest = await this.getDatesFromWeekNumV2(this.vineyardService.weekOfYear);
    this.startDate = format(new Date(datesTest["monday"]), "dd.MM.yyyy");

    this.endDate = format(new Date(endOfWeek(new Date(datesTest["monday"]))), "dd.MM.yyyy")
    // console.log("DATES TEST", datesTest, this.startDate, this.endDate, format(this.endDate, 'yyyy-MM-dd HH:mm:ss'));
  }
}
