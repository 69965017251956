import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from '@angular/fire/firestore';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from 'app/_services/global.service';
import { NotificationsService } from 'app/_services/notifications.service';
import { untilDestroyed } from 'ngx-take-until-destroy';
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  test: Date = new Date();
  firestoreAppVersion: any;
  copyright: any;

  constructor(
    private afs: AngularFirestore,
    public notif: NotificationsService,
    private translate: TranslateService,
    public global: GlobalService,
    public http: HttpClient
  ) { }
  getCopyright() {

    this.http.get(`https://2025.galati.sk/api-25/copyright/?lang=${this.global.lang}`)
      .toPromise().then((res: Response) => {
        console.log("footer", res)
        this.copyright = res["copyright"];
      })
  }

  ngOnInit() {
    this.afs.collection("VinLink_App").doc("2025").valueChanges().pipe(untilDestroyed(this)).subscribe((res) => {
      this.firestoreAppVersion = res["appVersion"];
      if (this.firestoreAppVersion > this.global.appVersion) {
        this.notif.toaster(this.translate.instant("Nepoužívate najnovšiu verziu aplikácie. Pre načítanie novšej verzie obnovte túto stránku v prehliadači."))
      }
    })
    this.global.langObservable.subscribe((res) => {
      this.getCopyright()
    })

  }
  ngOnDestroy() {
    // To protect you, we'll throw an error if it doesn't exist.
  }
}
