import { trigger, transition, style, animate } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Vineyard } from 'app/_classes/vineyard';
import { AuthenticationService } from 'app/_services/authentication.service';
import { GalatiService } from 'app/_services/galati.service';
import { GlobalService } from 'app/_services/global.service';
import { VineyardService } from 'app/_services/vineyard.service';
import { WeatherService } from 'app/_services/weather.service';
import { getDay, isWithinInterval, parseISO, startOfWeek } from 'date-fns';
import { untilDestroyed } from 'ngx-take-until-destroy';

@Component({
  selector: 'app-situation',
  templateUrl: './situation.component.html',
  styleUrls: ['./situation.component.scss'],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(".25s ease-out", style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate(".25s ease-in", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class SituationComponent implements OnInit {
  @Input() vineyard: Vineyard;

  private previousVineyard: any;
  currentWeek: any;
  diseaseManagementSchemes;
  limit = 4;
  timeFrame = "thisWeek";
  currentWeekSituation: any;
  currentWeekData: any;
  nextWeek = null;

  previousWeek: any;
  dailyWeek: Object;

  constructor(public http: HttpClient,
    public cdr: ChangeDetectorRef,
    public global: GlobalService,
    public galatiService: GalatiService,
    public globalService: GlobalService,
    public vineyardService: VineyardService,
    public weatherService: WeatherService,
    public authService: AuthenticationService,
    private _ActivatedRoute: ActivatedRoute,
    public router: Router
  ) {

    this.router.events.pipe(untilDestroyed(this)).subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this._ActivatedRoute.queryParams.subscribe((queryParam) => {
          console.log("-------url query param", queryParam);
          if (queryParam["timeFrame"]) {
            this.changeTimeFrame(queryParam["timeFrame"]);
          } else {
            this.changeTimeFrame("thisWeek");
          }
        })
      }
    }).unsubscribe();
  }

  changeTimeFrame(timeFrame) {
    this.timeFrame = timeFrame;
    if (timeFrame === "history") { return }
    if (timeFrame === "nextWeek") {
      console.log("nextweek", this.vineyardService.weekOfYear, this.vineyard.galatiSeason.season.at(-1).weekNum)
      if (this.vineyardService.weekOfYear == this.vineyard.galatiSeason.season.at(-1).weekNum) {
        this.http
          .get(
            `https://api.openweathermap.org/data/2.5/onecall?lat=${this.vineyard.lat}&lon=${this.vineyard.lng}&lang=${this.global.lang}&units=metric&exclude=current,minutely,hourly&appid=${this.global.owmApiKey}`
          ).toPromise().then((result) => {
            console.warn(result);
            let rainSUM = 0;
            let tempSum = 0;
            let avgTemp;
            let days = 0;
            result["daily"].forEach((day) => {
              days++;
              tempSum += (day.temp.min + day.temp.max) / 2;
              if (day.rain) {
                rainSUM += day.rain;
              }
            });
            rainSUM = +(rainSUM.toFixed(1));
            avgTemp = +((tempSum / days).toFixed(1));
            // console.warn("rainsum, avgTemp", rainSUM, avgTemp);


            this.http
              .post(
                `https://2025.galati.sk/api-25/input/forecast/?key=${this.vineyard.galatiKey}&lang=${this.global.lang}`,
                {
                  temp: avgTemp,
                  rainfall: rainSUM,
                }
            ).toPromise().then(res => {
              // console.log("forecast", res);
              this.nextWeek = res["season"].at(-1);
              ;
            }).catch((err) => {
              console.error("forecast error", err);
            });



          })
      }
    }
    if (timeFrame === "today") {
      if (this.vineyard.galatiSeason.season.at(-1).weekNum == this.vineyardService.weekOfYear) {
        const startOfCurrentWeek = startOfWeek(new Date());

        this.weatherService.getMeteoData(this.global.nowSubDays(7), this.vineyard.StationID).toPromise().then(res => {
          this.vineyard.meteoData = res;
          const currentWeekData = this.vineyard.meteoData.filter(data => isWithinInterval(parseISO(data.insert_datetime), { start: startOfCurrentWeek, end: new Date() }));

          const temperatures = currentWeekData.map(data => Number(data.temperature));
          const averageTemperature = +(temperatures.reduce((sum, temperature) => sum + temperature, 0) / temperatures.length).toFixed(1);

          const rainDays = currentWeekData.filter(data => Number(data.rain) > 0).map(data => data.insert_datetime);
          const cumulativeRain = currentWeekData.reduce((sum, data) => sum + Number(data.rain), 0);

          const oneRainDay = rainDays.length === 1;

          // console.log(`Average temperature for the last 7 days: ${averageTemperature}`);
          // console.log(`Cumulative rain for the last 7 days: ${cumulativeRain}`);
          // console.log(`There was only one day with rain in the last 7 days: ${oneRainDay}`);

          this.http
            .post(
              `https://2025.galati.sk/api-25/input/daily/?key=${this.vineyard.galatiKey}&lang=${this.global.lang}&responseType=simple`,
              {
                weekNum: this.vineyardService.weekOfYear,
                weekDay: getDay(new Date()),
                temp: averageTemperature,
                rainfall: cumulativeRain,
                impulsiveRainfall: oneRainDay,
              }
            ).toPromise().then(galDaily => {
              // console.warn("galDaily", galDaily)
              this.dailyWeek = galDaily["situation"];
              this.dailyWeek["previousWeek"] = {};
              this.dailyWeek["previousWeek"].rainfall = parseFloat(cumulativeRain).toFixed(1);
              this.dailyWeek["previousWeek"].temp = averageTemperature.toFixed(1);
            }).catch(err => { console.log("err", err) })


        }

        )
      }
    }
    if (timeFrame === "thisWeek") {
      let currentWeekIndex = this.vineyard.galatiSeason.season.findIndex(x => x.weekNum === this.vineyardService.weekOfYear);
      this.currentWeekData = this.vineyard.galatiSeason.season[currentWeekIndex];

      let previousWeekIndex = currentWeekIndex - 1;
      this.previousWeek = this.vineyard.galatiSeason.season[previousWeekIndex];
    }
  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe((queryParam) => {
      // console.log("ngoninit change timeframe", queryParam);
      if (queryParam["timeFrame"]) {
        this.changeTimeFrame(queryParam["timeFrame"]);
      } else {
        this.changeTimeFrame("thisWeek");
      }
    }).unsubscribe()
    // console.log("VINEYARD", this.vineyard, this.vineyard.galatiSeason);
    // // this.week = this.vineyard.galatiSeason.season[5];
    // if (this.vineyard.galatiSeason && this.vineyard.galatiSeason.season) {
    //   this.currentWeek = this.vineyard.galatiSeason.season[this.vineyard.galatiSeason.season.length - 1];
    // }

    this.http.get(`https://2025.galati.sk/api-25/diseaseManagementScheme/?lang=${this.global.lang}`).pipe(untilDestroyed(this)).subscribe((res) => {
      this.diseaseManagementSchemes = res;
    });


    // // let nextWeekIndex = this.vineyard.galatiSeason.season.findIndex(x => x.weekNum === this.vineyardService.weekOfYear + 1);
    // // this.nextWeek = this.vineyard.galatiSeason.season[nextWeekIndex];




  }
  ngAfterContentInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.


  }
  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.

  }
}

