import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject,
  SimpleChanges,
} from "@angular/core";
import { Vineyard } from "app/_classes/vineyard";
import { WeatherService } from "app/_services/weather.service";
import { VineyardService } from "app/_services/vineyard.service";
import { MapService } from "app/_services/map.service";
import { trigger, transition, style, animate } from "@angular/animations";
import { TranslateService } from "@ngx-translate/core";
import { GlobalService } from "app/_services/global.service";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { DialogData } from "app/user-profile/user-profile.component";
import { GalatiService } from "app/_services/galati.service";
import { getDay, fromUnixTime, subDays, format } from "date-fns";
import { Infect } from "app/_classes/Infect";
import { untilDestroyed } from "ngx-take-until-destroy";
import { HttpClient } from "@angular/common/http";
import { AngularFireAuth } from '@angular/fire/auth';
import { NotificationsService } from 'app/_services/notifications.service';
import { AuthenticationService } from 'app/_services/authentication.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable } from 'rxjs/Observable';

declare var L: any;

@Component({
  selector: "app-vineyard-card",
  templateUrl: "./vineyard-card.component.html",
  styleUrls: ["./vineyard-card.component.scss"],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(".25s ease-out", style({ opacity: 0.3 })),
      ]),
      transition(":leave", [
        style({ opacity: 0.3 }),
        animate(".25s ease-in", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class VineyardCardComponent implements OnInit {
  map;
  locale;
  showMorePheno = false;
  showMoreMeteo = true;
  phenoFix = false;
  @Input() vineyard: Vineyard;
  @Output() vineyardRemoveEmitted: EventEmitter<any> = new EventEmitter();
  chosenPhenophase;
  phenoChoices = [];
  weeksUntilSeasonEnd: any;
  infect_m = new Infect();
  infect_p = new Infect();
  infect_b = new Infect();
  phenophase: any;
  phenoEditing = true;
  currentPheno: any;
  pestsShown = true;
  worksExpanded = false;
  weekOfYear: any;

  forecast = [];
  phenoOpen = false;
  tipsOpen = false;
  meteoOpen = false;
  currentWeek: any;
  signalNote = {};
  phenoFixOptions: any;
  phenoToDelete: any;

  constructor(
    public mapService: MapService,
    public vineyardService: VineyardService,
    public translate: TranslateService,
    public globalService: GlobalService,
    public galatiService: GalatiService,
    public dialog: MatDialog,
    public weatherService: WeatherService,
    public global: GlobalService,
    public http: HttpClient,
    public fireAuth: AngularFireAuth,
    public notif: NotificationsService,
    public authService: AuthenticationService,
    public ws: WeatherService,

    public fns: AngularFireFunctions

  ) {
    this.signalNote['p'] = false;
    this.signalNote['b'] = false;
    this.signalNote['m'] = false;



  }
  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.

    this.gatherData(this.vineyard);
  }

  phenoBefore(key) {
    return this.vineyard.galatiSeason.season.find(o => o.weekNum === key);
  }
  phenoFixFn() {
    this.phenoFix = true;
    this.http.get(`https://2025.galati.sk/api-25/input/fix/?key=${this.vineyard.galatiKey}&lang=${this.global.lang}`).toPromise().then((res: any) => {
      console.log("phenofix", res);
      this.phenoFixOptions = res.selection.weekOptions;
    }).catch(err => {
      console.log("phenofix", err)
    })

  }
  deletePheno() {
    this.http.post(`https://2025.galati.sk/api-25/input/fix/?key=${this.vineyard.galatiKey}&lang=${this.global.lang}&responseType=full`, {
      weekNum: this.phenoToDelete
    }).toPromise().then(res => {
      console.log("delete pheno", res);
      this.notif.toaster(this.translate.instant("Sezóna úspešne upravená"), "", "success");
      this.vineyard.galatiSeason = res;
    }).catch(err => {
      console.warn("pheno delete", err)
      this.notif.toaster(this.translate.instant("Sezóna nebola upravená"), "", "danger");
    }).finally(() => {
      this.phenoFix = false;
      this.vineyardService.getMyVineyards();
    })
  }

  ngOnInit() {
    // let usersWithoutOwner = structuredClone(this.vineyard.users);
    // delete usersWithoutOwner[this.vineyard.ownerUID];
    // const adresati = Object.keys(usersWithoutOwner ?? {}).map(key => ({
    //   email: this.vineyard.users[key],
    //   // email: "jan.trgina@vinlink.eu",
    //   name: this.vineyard.users[key],
    // }));
    // console.log(
    //   "toto by sa poslalo",
    //   {
    //     Alias: this.vineyard.Alias,
    //     users: this.vineyard.users,
    //     usersWithoutOwner: usersWithoutOwner,
    //     owner: this.vineyard.ownerUID,
    //     email: this.vineyard.users[this.vineyard.ownerUID],
    //     name: this.vineyard.users[this.vineyard.ownerUID],
    //     cc: adresati,
    //   });

    this.locale = this.globalService.lang;
    this.globalService.langObservable
      .pipe(untilDestroyed(this))
      .subscribe((lang) => {
        if (lang === "cz") {
          this.locale = "cs";
        } else {
          this.locale = lang;
        }
      });
    this.galatiService
      .galatiInputGet(this.vineyard.galatiKey)
      .then((res) => {
        this.phenoChoices = res["phenoOptions"];
      });

    // this.gatherData();
    this.getForecast(this.vineyard).toPromise().then((res) => {
      for (let i = 0; i < 4; i++) {
        this.forecast.push(res["daily"][i]);
      }
    });


  }
  // getForecast(vineyard) {
  //   return this.http.get(
  //     `https://api.openweathermap.org/data/2.5/onecall?lat=${vineyard.lat}&lon=${vineyard.lng}&lang=${this.locale}&units=metric&exclude=current,minutely,hourly&appid=${this.global.owmApiKey}`
  //   );
  // }


  testCallable() {

    const testCall = this.fns.httpsCallable('testCall');
    testCall({ text: "ABCD" }).subscribe(obs => {
      console.log("obs", obs)
    })
    const uids = Object.keys(this.vineyard.users);
    // return uids;
    console.log("sending to", uids);
  }



  sendMejl() {

    const sendMailAfterPheno = this.fns.httpsCallable('mailAfterPheno4');
    sendMailAfterPheno({
      vineyard: this.vineyard,
      lang: this.global.lang
    }).toPromise().then(mailRes => {
      console.log("mail RES", mailRes);
    }).catch(mailErr => {
      console.warn("mail ERR", mailErr);
    })
  }




  getForecast(vineyard: any): Observable<any> {
    const forecastCacheKey = `forecast_${vineyard.lat}_${vineyard.lng}`;
    const cachedForecast = localStorage.getItem(forecastCacheKey);

    if (cachedForecast) {
      const { lastFetch, data } = JSON.parse(cachedForecast);
      const timeSinceLastFetch = (new Date().getTime() - new Date(lastFetch).getTime()) / (1000 * 60); // Convert to minutes

      if (timeSinceLastFetch < 30) {
        return Observable.of(data); // Use Observable.of if RxJS 6 or below, or use of from rxjs in RxJS 7+
      }
    }

    return this.http.get(
      `https://api.openweathermap.org/data/2.5/onecall?lat=${vineyard.lat}&lon=${vineyard.lng}&lang=${this.locale}&units=metric&exclude=current,minutely,hourly&appid=${this.global.owmApiKey}`
    ).map(result => {
      // Cache the new forecast data
      const cacheValue = {
        lastFetch: new Date().toISOString(),
        data: result
      };
      localStorage.setItem(forecastCacheKey, JSON.stringify(cacheValue));
      return result;
    })

  }




  getLargestLevel(): number {
    const { p, m, b } = this.currentWeek;
    return Math.max(p.level, m.level, b.level);
  }






  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }

  gatherData(vineyard) {
    this.galatiService.galatiCalendar(new Date()).then((calRes) => {
      this.weekOfYear = calRes["weekNum"]; // ! toto je produkcia
      this.galatiService.getSeason(vineyard).then((data) => {
        vineyard.galatiSeason = data;
        if (vineyard.galatiSeason.season) {
          this.currentWeek = vineyard.galatiSeason.season[vineyard.galatiSeason.season.length - 1];
          console.warn("current week", this.currentWeek);
        }
        this.getInfections(vineyard);
      }).catch(error => {
        console.log("chyba galati get season ", error, vineyard);
      });
    });
  }

  getInfections(vineyard) {
    let season = vineyard.galatiSeason.season;
    console.log(season);
    if (season) {
      this.infect_m = season[season.length - 1]["m"];
      this.infect_p = season[season.length - 1]["p"];
      this.infect_b = season[season.length - 1]["b"];
      this.getIconFromMsg(this.infect_m, this.infect_b, this.infect_p);
    }
  }
  unixToTime(timestamp) {
    return fromUnixTime(timestamp);
  }

  iconClassForInfection(infectName) {
    let infect = this.vineyard.galatiSeason.season[
      this.vineyard.galatiSeason.season.length - 1
    ][`${infectName}`];

    if (infect["level"] === 0) {
      infect["icon"] = "done";
      infect["class"] = "disease-status--0";
    } else if (infect["level"] === 1) {
      infect["icon"] = "trending_down";
      infect["class"] = "disease-status--1";
    } else if (infect["level"] === 2) {
      infect["icon"] = "error_outlined";
      infect["class"] = "disease-status--2";
    }
    return infect;
  }
  getIconFromMsg(m, b, p) {
    // console.log("MPB1:", m, p, b);
    [m, b, p].forEach((el) => {
      // console.log("prave prebieha: ", el);
      if (el["level"] === 0) {
        el["icon"] = "done";
        el["class"] = "disease-status--ok";
      } else if (el["level"] === 1) {
        el["icon"] = "trending_down";
        el["class"] = "disease-status--low";
      } else if (el["level"] === 2) {
        el["icon"] = "error";
        el["class"] = "disease-status--high";
      }
      // console.log("dobehol ", el);
    });
    // console.log("MPB:", m, p, b, this.infect_m, this.infect_p, this.infect_b);
  }

  openPhenoInfoDialog() {
    const dialogRef = this.dialog.open(DialogPhenophasesInfo, {
      width: "750px",
    });
    dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        console.log(`Dialog result: ${result}`);
      });
  }
  onVineyardRemove(vineyard) {
    this.vineyardRemoveEmitted.emit(vineyard);
  }

  onMapReady(e) {
    e.setView([this.vineyard.lat, this.vineyard.lng], 13);
    this.mapService.mapInit(e);
    this.map = e;
    this.map.removeControl(this.map.zoomControl);
    const marker = new L.marker([this.vineyard.lat, this.vineyard.lng], {
      icon: this.mapService.stationIcon,
    })
      .addTo(this.map)
      .bindPopup(
        `${this.translate.instant("device.Device")}: ${this.vineyard.Alias}`
      )
      .openPopup();
  }
  ngOnDestroy() {
    // To protect you, we'll throw an error if it doesn't exist.
  }
}
@Component({
  selector: "dialog-phenophases-info",
  templateUrl: "../../dialogs/phenophases-info/dialog-phenophases-info.html",
  styleUrls: ["../../dialogs/phenophases-info/dialog-phenophases-info.scss"],
})
export class DialogPhenophasesInfo {
  passwd: string;
  constructor(
    public dialogRef: MatDialogRef<DialogPhenophasesInfo>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
